<template>
  <div class="recharge-page ">
    <headerBar :title="i18n.t('withdraw.title')" class="green" :showArrow="true" @back="back"></headerBar>

    <div class="recharge-top">
      <div class="label">{{i18n.t('withdraw.label1')}}($)</div>
      <div class="money">$ {{parseFloat(userMoney).toFixed(2)}}</div>
      <div class=" tabs">
        <div class="flex-center-between tab">
          <div class="tab-label">{{i18n.t('withdraw.label2')}}($)</div>
          <div class="count">{{parseFloat(applying).toFixed(2)}}</div>
        </div>
        <div class="flex-center-between tab">
          <div class="tab-label">{{i18n.t('withdraw.label3')}}($)</div>
          <div class="count ">{{parseFloat(total).toFixed(2)}}</div>
        </div>
      </div>
    </div>

    <div class="sub-title">{{i18n.t('withdraw.label4')}}</div>

    <div class="recharge-form withdraw">
      <div class="tags flex-wrap">
        <div class="tag" :class="tagIndex == 1 ? 'active' : ''" @click="changeTag(1)">USDT</div>
        <div class="tag" :class="tagIndex == 2 ? 'active' : ''" @click="changeTag(2)">BANK</div>
      </div>
      <div class="input">
        <input type="number" v-model="form.number" autocomplete="off" :placeholder="i18n.t('withdraw.placeholder1')">
        <div class="btn">
          <Button @click="setAll">{{i18n.t('withdraw.btn')}}</Button>
        </div>
      </div>

      <div class="form-item" v-if="tagIndex == 1">
        <div class="label">{{$t('withdraw.label6')}}</div>
        <div class="form-input">
          <input type="text" v-model="form.address" autocomplete="off" :placeholder="$t('withdraw.placeholder2')">
        </div>
      </div>



      <div class="form-item" v-if="tagIndex == 2">
        <div class="label">{{$t('withdraw.label12')}}</div>
        <div class="form-input">
          <input type="text" v-model="form.BankName" autocomplete="off" :placeholder="$t('withdraw.placeholder4')">
        </div>
      </div>

      <div class="form-item" v-if="tagIndex == 2">
        <div class="label">{{$t('withdraw.label13')}}</div>
        <div class="form-input">
          <input type="text" v-model="form.BankCard" autocomplete="off" :placeholder="$t('withdraw.placeholder5')">
        </div>
      </div>

      <div class="form-item" v-if="tagIndex == 2">
        <div class="label">{{$t('withdraw.label14')}}</div>
        <div class="form-input">
          <input type="text" v-model="form.CardName" autocomplete="off" :placeholder="$t('withdraw.placeholder6')">
        </div>
      </div>

      <div class="form-item" v-if="tagIndex == 2">
        <div class="label">{{$t('withdraw.label15')}}</div>
        <div class="form-input">
          <input type="text" v-model="form.Phone" autocomplete="off" :placeholder="$t('withdraw.placeholder7')">
        </div>
      </div>

      <div class="form-item">
        <div class="label">{{$t('withdraw.label7')}}</div>
        <div class="form-input">
          <input type="password" v-model="form.password" autocomplete="off" :placeholder="$t('withdraw.placeholder3')">
        </div>
      </div>

      <div class="submit-btn">
        <button @click="submit">{{i18n.t('withdraw.btn1')}}</button>
      </div>
    </div>

  </div>
</template>
<script>
import { Button, Form } from 'vant'
import headerBar from '@/components/header'
import {doWithdraw, getBankList, getUsdtList, userInfo, WithdrawalStatistics} from '@/api/user'
export default {
  components: {
    headerBar,
    Button
  },

  data() {
    return {
      form: {
        number: '',
        address: '',
        password: '',
        BankName: '',
        BankCard: '',
        CardName: '',
        Phone: '',
      },
      tagIndex: 1,
      allow :false,
      readonly :false,
      bankShow:false,
      usdtShow:false,
      BankList: [],
      UsdtList: [],
      // 正在申请的提现余额
      applying: 0.00,
      // 累计提现余额
      total: 0.00,
      userMoney: 0,
      fastList: [50, 100, 200, 300, 500, 600, 800, 1000, 2000, 5000, 8000, 10000],
    }
  },
    computed: {
        withdraw_bind_address() {
            return this.$store.state.withdraw_bind_address
        },
      withdraw_bind_bank() {
        return this.$store.state.withdraw_bind_bank
      },
      show_collection() {
        return this.$store.state.show_collection
      },
    },
  watch: {
    form: {
      handler(val) {
        if(val.type === "bank") {
          // 检查银行卡数量
          if(this.BankList.length === 0) {
            this.$toast({
              message: this.i18n.t('withdraw.bind_bank'),
              icon: 'warning-o',
            })
            setTimeout(() => {
              this.$router.push({
                name: "bindCard"
              })
            }, 1000)
          }
        }
        if(val.type === "usdt") {
          // 检查银行卡数量
          if(this.UsdtList.length === 0) {
            if(this.show_collection === 0) {
              this.$toast({
                message: this.i18n.t('withdraw.bind_address'),
                icon: 'warning-o',
              })
            } else {
              this.$toast({
                message: this.i18n.t('withdraw.bind_usdt_address'),
                icon: 'warning-o',
              })
            }

            setTimeout(() => {
              this.$router.push({
                name: "bind"
              })
            }, 1000)
          }
        }
      },
      deep: true
    }
  },

  mounted() {
    this.init()
  },
  methods: {
    initList() {
      // 确定是否需要绑定地址才可以提现
      getUsdtList().then(res =>{
        this.usdtShow = true
        if (res.code === 0) {
          this.UsdtList = res.data.list
          if(res.data.list.length === 1) {
            this.form.address = res.data.list[0].address
          }
        }
      })
      getBankList().then(res =>{
        console.log(res.data)
        this.bankShow = true
        if (res.code === 0) {
          this.BankList = res.data.Items
          if(res.data.Items.length === 1) {
            this.form.bank = res.data.Items[0].RealName + this.$i18n.t("bind.Company") + " : " + res.data.Items[0].Company + this.$i18n.t("bind.Card") +  " ：" + res.data.Items[0].Card
            if(res.data.Items[0].TaxID.length > 0) {
              this.form.bank += this.$i18n.t("bind.TaxID") +" ：" + res.data.Items[0].TaxID
            }
          }
        }
      })
      // 确定是否需要绑定地址才可以提现
      if(this.withdraw_bind_address == 1) {
          getUsdtList().then(res =>{
              if (res.code === 0) {
                  console.log(res.data.list)
                  if(res.data.list.length === 1) {
                      this.allow = true
                      this.form.address = res.data.list[0].address
                      this.readonly = true
                  } else {
                      this.readonly = true
                      this.allow = false
                      this.$toast({
                          message: this.i18n.t('withdraw.bind_address'),
                          icon: 'warning-o',
                      })
                      setTimeout(() => {
                          this.$router.push({
                              name: "bind"
                          })
                      }, 1000)
                  }
              }
          })
      } else {
          this.allow = true
          this.readonly = false
      }

    },
    init() {
      WithdrawalStatistics().then(res => {
        if (res.code === 0) {
          this.applying = res.data.NowPrice
          this.total = res.data.TotalPrice
        }
      })
      userInfo().then(res => {
        this.userMoney = res.data.UserAccount.Withdraw
      })
    },
    handleInput() {
      // 正则表达式匹配数字
      let regex = /\d+/g;
      let matches = this.form.number.match(regex); // ["123", "456"]
      console.log("matches")
      console.log(matches)
      this.form.number = matches[0]
    },
    back() {
      this.$router.go(-1)
    },
    submit() {
      if (!this.form.number) {
        this.$toast({
          message: this.i18n.t('withdraw.placeholder1'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.password) {
        this.$toast({
          message: this.i18n.t('withdraw.placeholder3'),
          icon: 'warning-o'
        })
        return
      }
      let form = new FormData()
      form.append('Price', this.form.number)
      form.append('Passwd', this.form.password)
      if (this.tagIndex == 1) {
        if (!this.form.address) {
          this.$toast({
            message: this.i18n.t('withdraw.placeholder2'),
            icon: 'warning-o'
          })
          return
        }
        form.append('Address', this.form.address)
      } else {
        if (!this.form.BankName) {
          this.$toast({
            message: this.i18n.t('withdraw.placeholder4'),
            icon: 'warning-o'
          })
          return
        }
        if (!this.form.BankCard) {
          this.$toast({
            message: this.i18n.t('withdraw.placeholder5'),
            icon: 'warning-o'
          })
          return
        }
        if (!this.form.CardName) {
          this.$toast({
            message: this.i18n.t('withdraw.placeholder6'),
            icon: 'warning-o'
          })
          return
        }
        if (!this.form.Phone) {
          this.$toast({
            message: this.i18n.t('withdraw.placeholder7'),
            icon: 'warning-o'
          })
          return
        }
        form.append('Type', 'bank')
        form.append('BankName', this.form.BankName)
        form.append('BankCard', this.form.BankCard)
        form.append('CardName', this.form.CardName)
        form.append('Phone', this.form.Phone)
      }
      doWithdraw(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('withdraw.success'))
          this.init()
        } else {
          this.$toast(res.msg)
        }
      })
    },
    setAll() {
      this.form.number = parseInt(this.userMoney,10)
    },
    changeTag(i) {
      this.tagIndex = i
    },
  }
}
</script>